<template>
  <van-action-sheet v-model="visible" :title="title" :closeable="false" @closed="closed" @open="open">
    <template>
      <div class="contract-list">
        <van-checkbox-group v-model="activeList">
          <van-checkbox shape="square" icon-size="0.4rem" v-for="item in contracList" :key="item.id" :name="item.id"
            :disabled="active.includes(item.id)" @click="checkboxClick(item)">
            <div class="checkbox-item">
              <img class="type-img" :src="item.logo || require('@/assets/imgs/signFile/pdf.svg')" />
              <div class="name">{{ item.name }}</div>
            </div>
          </van-checkbox>
        </van-checkbox-group>
      </div>
      <div class="group-button">
        <van-button class="btn" plain type="info" @click="cancel">
          取消
        </van-button>
        <van-button class="btn last" type="info" @click="confirm">
          确认
        </van-button>
      </div>
    </template>
  </van-action-sheet>
</template>

<script>
import { Dialog } from 'vant'
import templateFileApis from '@/api/core/templateFile'

export default {
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
      required: true,
    },
    title: {
      type: String,
      default: '',
      required: true,
    },
    active: {
      type: Array,
      default: () => [],
    },
    type: {
      type: Number,
      default: 0, // 0: 选择应用模板 2: 选择合同模板
    },
    // 单选or多选
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      contracList: [],
      activeList: [],
    }
  },
  computed: {
    visible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      },
    },
  },
  watch: {
    active(val) {
      this.activeList = val
    },
  },
  methods: {
    /** 页面初始化 */
    async open() {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      })
      try {
        const res = await templateFileApis.getHomePageClassifyInfoList({
          pageNum: 1,
          pageSize: 1000,
          searchType: this.type,
        })
        const { rowsList } = res
        this.contracList = rowsList
        this.$toast.clear()
      } catch (error) {
        this.$toast.clear()
        console.error(error)
      }
    },

    /** 单选时关闭弹窗 */
    checkboxClick(item) {
      if (this.multiple) return

      this.$emit('confirm', item.id, item)
      this.cancel()
    },

    /** 取消 */
    cancel() {
      this.$emit('update:show', false)
    },

    /** 确定 */
    confirm() {
      this.$emit('confirm', this.activeList)
    },

    /** 关闭 */
    closed() {
      this.contracList = []
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .van-action-sheet__header {
  border-bottom: 1px solid #ededed;
}

.contract-list {
  height: calc(69vh - 56px);
  overflow: hidden auto;
  padding-left: 14px;

  /deep/ .van-checkbox__label {
    width: 100%;
  }

  .checkbox-item {
    display: flex;
    align-items: center;

    .type-img {
      width: 34px;
      height: 34px;
      padding-left: 12px;
      box-sizing: content-box;
    }

    .name {
      height: 50px;
      font-size: 14px;
      color: #111a34;
      margin-left: 14px;
      border-bottom: 1px solid rgba(205, 205, 205, 0.5);
      display: flex;
      align-items: center;
      flex: 1;
    }
  }

  .des {
    font-size: 14px;
    color: #969799;
    padding: 0 14px;
    text-align: center;
  }
}

.group-button {
  display: flex;
  align-items: center;
  height: 56px;

  .btn {
    flex: 1;
    margin: 0 14px 0;
  }

  .last {
    margin-left: 0;
  }
}
</style>
